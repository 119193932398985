import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  Layout, Menu,
  Button, Dropdown, Avatar
} from 'antd'
import { LogoutOutlined, LockOutlined } from '@ant-design/icons'
// import styled from 'styled-components'
import routes from '../routes'
import history from '../utils/History'
import { doLogout } from '../actions/Auth'
import { Sidebar } from '.'
import { first } from 'lodash'
import InfoData from './InfoData'
import page404 from '../pages/page404'
const { Header, Content } = Layout

const WrapperContent = Layout

class Wrapper extends React.Component {
  state = {
    collapsed: false,
    newRoutes: [],
  }

  componentDidMount(){
    const { auth, location: {pathname} } = this.props
    const userRole = first(auth?.roles)?.role_name

    if (!auth.isAuthenticated && (pathname !== "/login")) {
      history.push('/login')
    }

    let reRoutes = []
    routes.forEach(item => {
      const newValue = item?.allowedRoles?.includes(userRole) && item
      newValue && reRoutes.push(newValue)
    })

    this.setState({
      newRoutes: reRoutes
    })
  }

  onLogout = () => {
    this.props.doLogout()
  }

  componentDidUpdate(prevState) {
    const { auth, location: {pathname} } = this.props
    if (auth.isAuthenticated !== prevState.auth.isAuthenticated) {
      const { isAuthenticated } = auth
      if (!isAuthenticated && (pathname !== "/login")) {
        history.push('/login')
      }
    }
  }

  render() {
    const { auth } = this.props
    const { collapsed, newRoutes } = this.state
    
    let initialName = auth.name ? (auth.name).match(/\b\w/g) : []
    initialName = ((initialName.shift() || '') + (initialName.pop() || '')).toUpperCase()

    const menuItems = [
      {
        key: '0',
        label: (
          <Button
            type="link"
            onClick={this.onLogout}
            icon={<LogoutOutlined />}
          >
            Keluar
          </Button>
        ),
      },
      {
        key: '1',
        label: (
          <Button
            type="link"
            onClick={() => history.push('/change-password')}
            icon={<LockOutlined />}
          >
            Ganti Password
          </Button>
        ),
      },
    ]    
    const menu = (
      <Menu items={menuItems} />
    )

    return (
      <Layout style={{minHeight:'100vh'}}>
        <Sidebar
          routes={routes}
          isCollapsed={collapsed}
          toggleCollapse={(event) => this.setState({ collapsed: event })}
        />
        <WrapperContent>
          <Header className="site-layout-sub-header-background" style={{ padding: 0, background: '#fff' }} >
            <div className="d-flex justify-content-end mx-2">
              <Dropdown overlay={menu} trigger={['click']}>
                <div className="align-self-center cursor-pointer">
                  <span className="admin-name mr-2">{auth.name}</span>
                  <Avatar className="avatar-nav nav-text mr-lg-3 mr-2" size={40}>
                    {initialName}
                  </Avatar>
                </div>
              </Dropdown>
            </div>
          </Header>
          <Content style={{padding: 24, margin: 0}}>
            <InfoData/>
            <Switch>
              {(
                newRoutes?.map((route, idx) => (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={(props) => <route.component {...props} />}
                  />
                ))
              )}
              <Route component={page404} />
            </Switch>
          </Content>
        </WrapperContent>
      </Layout>
    )
  }
}

export default connect(
  ({ auth }) => ({ auth }),
  {doLogout}
)(Wrapper)
