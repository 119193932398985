import React, { useState } from "react";
import { connect } from "react-redux";
import { Container, Card, Row, Col, Button } from "react-bootstrap";
import { Form, Input } from "antd";
import { logIn, agreementUpdate } from "../actions/Auth";
import { forgotPassword } from "../actions/Password";
import Swal from "sweetalert2";
import { AgreementModal } from "../components";
import history from "../utils/History";
import * as Setting from "../utils/CasdoorInit";

const Login = ({ logIn, forgotPassword, agreementUpdate }) => {
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLogin, setIsLogin] = useState(true);
  const [userRes, setUserRes] = useState({});
  const [isSubmitAgreement, setIsSubmitAgreement] = useState(false);
  const [toggleAgreementModal, setToggleAgreementModal] = useState(false);
  const [form] = Form.useForm();
  const sdk = Setting.CasdoorSDK;

  const onFinish = (values) => {
    setIsSubmit(true);

    logIn(values)
      .then((result) => {
        setIsSubmit(false);
        localStorage.setItem("id", result?.data?.id);
        setUserRes(result.data);
        if (!result?.data?.is_agree) {
          setToggleAgreementModal(true);
        }
      })
      .catch((error) => {
        setIsSubmit(false);
      });
  };

  const onFinishForgot = (values) => {
    setIsSubmit(true);
    forgotPassword(values)
      .then(() => {
        setIsSubmit(false);
        form.resetFields();
        Swal.fire(
          "Link Reset Password sudah dikirim",
          "Silahkan cek Email Anda",
          "success"
        ).then(() => {
          setIsLogin(true);
        });
      })
      .catch((error) => {
        setIsSubmit(false);
      });
  };

  const handleSwitchLogin = () => {
    setIsLogin(!isLogin);
    form.resetFields();
  };

  const handleSubmitAgreement = (value) => {
    agreementUpdate({ is_agree: value }, userRes.id, userRes?.id_token)
      .then(() => {
        history.push("/");
      })
      .catch(() => {
        setIsSubmitAgreement(false);
      });
  };

  return (
    <React.Fragment>
      <div className="h-100">
        <div className="d-flex align-items-center flex-column justify-content-center h-100">
          <Container>
            <Row className="justify-content-center">
              <Col xs="12" md="8" lg="4" className="m-b-10">
                <Card className="card-login login-pg">
                  {isLogin ? (
                    <Card.Body>
                      <div className="mb-2 text-center">
                        <h4>Dashboard SIPLah</h4>
                        <h6>
                          Kementerian Pendidikan
                          <br />
                          Dasar & Menengah
                          <br/>
                          Republik Indonesia
                        </h6>
                      </div>
                      <div className="text-center py-3">
                        <img
                          src="/Assets/img/logo.png"
                          style={{ width: "120px", heigh: "auto" }}
                          alt=""
                        />
                      </div>
                      <Form name="login" onFinish={onFinish} form={form}>
                        <Form.Item
                          name="email"
                          rules={[
                            { required: true, message: "Email harus diisi" },
                          ]}
                        >
                          <Input placeholder="Email" autoComplete="off" />
                        </Form.Item>

                        <Form.Item
                          name="password"
                          rules={[
                            {
                              required: true,
                              message: "Password atau kata sandi harus diisi",
                            },
                          ]}
                        >
                          <Input.Password
                            placeholder="Kata Sandi"
                            autoComplete="off"
                          />
                        </Form.Item>

                        {/*<Form.Item name="remember" valuePropName="checked">
                          <Checkbox>Ingat Saya</Checkbox>
                        </Form.Item>*/}

                        <Form.Item className="text-center m-b-0">
                          <Button
                            className="btn-block custom-primary"
                            variant="primary"
                            type="submit"
                          >
                            {isSubmit ? "Loading..." : "MASUK"}
                          </Button>
                          <Button
                            variant="link"
                            className="px-0"
                            onClick={handleSwitchLogin}
                          >
                            Lupa password
                          </Button>
                          <Button variant="link" href="/registration">
                            Mendaftar sebagai Pengawas
                          </Button>
                          {process.env.REACT_APP_CASDOOR_SSO === "true" && (
                            <>
                              <hr />
                              <Button
                                className="btn-block"
                                variant="secondary"
                                href={sdk.getSigninUrl()}
                              >
                                Masuk dengan Single Sign On
                              </Button>
                            </>
                          )}
                        </Form.Item>
                      </Form>
                    </Card.Body>
                  ) : (
                    <Card.Body>
                      <div className="mb-2 text-center">
                        <h5>Lupa Password</h5>
                        <h6>Masukan email yang digunakan</h6>
                      </div>
                      <div className="text-center py-3">
                        <img
                          src="/Assets/img/logo.png"
                          style={{ width: "120px", heigh: "auto" }}
                          alt=""
                        />
                      </div>
                      <Form name="forgot" onFinish={onFinishForgot} form={form}>
                        <div className="card-login">
                          <Form.Item
                            name="email"
                            rules={[
                              { required: true, message: "Email harus diisi" },
                            ]}
                          >
                            <Input placeholder="Email" />
                          </Form.Item>
                          <Form.Item className="text-center">
                            <Button
                              className="btn-block mt-4 custom-primary"
                              variant="primary"
                              type="submit"
                            >
                              {isSubmit ? "Loading..." : "Submit"}
                            </Button>
                            <Button
                              variant="link"
                              className="px-0"
                              onClick={handleSwitchLogin}
                            >
                              Sign In
                            </Button>
                          </Form.Item>
                        </div>
                      </Form>
                    </Card.Body>
                  )}
                </Card>
              </Col>
              <Col xs="12" className="text-center">
                <p>
                  {new Date().getFullYear()} © Kementerian Pendidikan Dasar & Menengah Republik Indonesia
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        <AgreementModal
          isApproveVisible={toggleAgreementModal}
          onSubmit={handleSubmitAgreement}
          isSubmitAgreementLoading={isSubmitAgreement}
        />
      </div>
    </React.Fragment>
  );
};

// const LoginForm = Form.create({ name: 'login' })(Login);

export default connect(() => ({}), { logIn, forgotPassword, agreementUpdate })(
  Login
);
