import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  Menu, Dropdown, Avatar,
  Row, Col, Button, Typography
} from 'antd'
import {
  HomeOutlined,
} from '@ant-design/icons';
import styled from 'styled-components'
import { updateSiteConfiguration } from '../../actions/site'
import { doLogout } from '../../actions/Auth'

const { Title } = Typography

const WrapperAvatar = styled(Row)`
  padding: 0 10px;
  .name {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    h4 {
      color: white;
      margin-bottom: 0;
      font-size: 16px;
    };
    .anticon-caret-down {
      margin-left: 5px;
      color: white;
    };
  };
`

const WrapperMenu = styled(Menu)`
  .ant-btn-link {
    padding: 0;
    color: unset;
  }
`

class Sidebar extends React.Component {

  onLogout = () => {
    this.props.doLogout()
  }

  render() {
    const { name } = this.props
    let initialName = name ? name.match(/\b\w/g) : []
    initialName = ((initialName.shift() || '') + (initialName.pop() || '')).toUpperCase()

    const menu = (
      <WrapperMenu>
        <Menu.Item key="0">
          <Button type="link"><HomeOutlined /> Profile</Button>
        </Menu.Item>
        <Menu.Item key="1">
          <Button type="link" onClick={this.onLogout}><HomeOutlined /> Logout</Button>
        </Menu.Item>
      </WrapperMenu>
    )
    return (
      <Dropdown
        menu={menu}
        trigger={['click']}
        overlayClassName="dropdown-profile"
      >
        <WrapperAvatar type="flex" justify="end" align="middle">
          <Col md={4} className="name">
            <div>
              <Avatar className="avatar-nav nav-text" size={44} style={{ marginRight: 5 }}>{initialName}</Avatar>
            </div>
            <Title level={4} ellipsis>{name}</Title>
            <HomeOutlined />
          </Col>
        </WrapperAvatar>
      </Dropdown>
    )
  }
}


export default withRouter(connect(
  ({ site }) => ({ site }),
  { updateSiteConfiguration, doLogout },
)(Sidebar))
