import React from 'react';
import {
  Button, Modal
} from 'antd';
import { connect } from 'react-redux';
import { agreementUpdate } from '../../actions/Auth';

class AgreementModal extends React.Component {

  state = {
    is_agree: false,
  }

  render(){
    const { isApproveVisible, isSubmitAgreementLoading } = this.props

    const onSubmitAgreement = (val) => {
      this.props.onSubmit(val)
    };

    return(
      <React.Fragment>
        <Modal
          title="Terms & Conditions"
          open={isApproveVisible}
          footer={null}
          width={800}
          closable={false}
          keyboard={false}
          centered
          style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}
        >
          <div className='eula-content'>
            <div className="mb-5">
              <h6 className="mb-3">Dengan mendaftar akun pengawas anda setuju dengan perlindungan privasi data pengguna sebagaimana diatur dalam undang-undang yang berlaku</h6>
              <h6>Penggunaan data yang ditampilkan adalah tanggung jawab pengguna, data yang ditampilkan tidak dapat digunakan tanpa seijin kementerian. Penggunaan data tanpa izin adalah tanggung jawab masing-masing pengguna</h6>
            </div>
            <div className="mb-5">
              <h6 className="text-center mb-3">
                PERJANJIAN LISENSI PENGGUNA (END USER LICENSE AGREEMENT)<br/>
                DASBOR PENGAWASAN SISTEM INFORMASI PENGADAAN SATUAN PENDIDIKAN (SIPLAH)
              </h6>
              <ol>
                <li><p>Dengan mendaftar akun sebagai Pengawas, Anda setuju terhadap perlindungan data pribadi berdasarkan EULA ini serta ketentuan peraturan perundangan-undangan yang berlaku.</p></li>
                <li><p>Anda bertanggung jawab untuk menjaga keamanan akun Anda, serta penggunaan data pribadi yang ditampilkan, termasuk namun tidak terbatas pada seluruh aktivitas yang terjadi di bawah penggunaan akun Anda.</p></li>
                <li><p>Anda tidak dapat menggunakan data yang ditampilkan pada Dasbor Pengawas SIPLah di luar dari ketentuan EULA ini. Apabila ditemukan penggunaan data tanpa izin dari Kami, segala konsekuensi yang berkaitan dengan hal tersebut menjadi tanggung jawab Anda.</p></li>
                <li><p>Kami telah menerapkan standar keamanan teknis dan organisasi yang sesuai untuk mencegah akses atau pengungkapan yang tidak sah, penyalahgunaan, penggantian, atau penghancuran informasi Anda. Untuk membantu melindungi privasi dan kerahasiaan Informasi Pribadi Anda, Kami meminta Anda untuk tidak berbagi kata sandi dengan orang lain, atau gunakan kata kunci atau kata sandi yang sama dengan yang telah digunakan untuk layanan lainnya.</p></li>
              </ol>
            </div>
            <div>
              <p className="mb-3">SAYA TELAH MEMBACA DAN MENGERTI SELURUH PERJANJAN LISENSI PENGGUNA/END USER LICENSE AGREEMENT (“EULA”) INI, DAN KONSEKUENSINYA, SERTA DENGAN INI MENERIMA SETIAP HAK, KEWAJIBAN, DAN KETENTUAN YANG DIATUR DI DALAMNYA.</p>
              <p>Perjanjian Lisensi Pengguna/End User License Agreement (“<b>EULA</b>”) adalah perjanjian antara Anda (“<b>Anda</b>” atau “<b>Pengguna</b>”) dan Pusat Data dan Teknologi Informasi Kementerian Pendidikan Dasar & Menengah Republik Indonesia (“<b>Kami</b>” atau “<b>Kemendikdasmen</b>”) yang mengatur mengenai bagaimana Pengguna menggunakan Dasbor Pengawasan Sistem Informasi Pengadaan Satuan Pendidikan (“<b>SIPLah</b>”) yang dimiliki oleh Kemendikdasmen. </p>
              <p>Dengan mendaftar sebagai Pengawas, Anda berarti telah menyetujui dan bersedia menerima semua persyaratan dan ketentuan EULA ini dalam melakukan langkah apapun untuk mengakses, mengunduh, mengkonfigurasi, mengatur, dan/atau menggunakan semua atau bagian apapun dari Dasbor Pengawasan SIPLah, media, atau dokumentasi lainnya yang disediakan untuk Anda oleh Dasbor Pengawasan SIPLah (secara bersama-sama disebut "<b>Produk</b>").</p>
            </div>
            <div>
              <ol>
                <li>
                  <p><b><u>Kepemilikan dan Reservasi Hak.</u></b> Kami memiliki semua hak, kuasa, dan kepentingan dalam dan pada Produk, termasuk semua hak kekayaan intelektual di dalamnya. 
                  Produk dilisensi, tidak dijual. Struktur, organisasi, dan aktivasi akun Produk merupakan informasi rahasia dan rahasia perdagangan yang berharga dari Kemendikdasmen  dan para pemasoknya. 
                  Produk dilindungi oleh hak cipta serta undang-undang dan perjanjian kekayaan intelektual lain, termasuk, namun tidak terbatas pada, undang-undang hak cipta Indonesia dan negara lain. 
                  Istilah Produk tidak mengacu pada atau meliputi Produk (“<b>Produk Pihak Ketiga</b>”). Produk berarti Produk tertentu yang dilisensi oleh Kemendikdasmen dari pihak ketiga yang dapat dilengkapi dengan versi khusus Produk yang telah Anda lisensikan. Produk Pihak Ketiga tidak diatur secara umum dalam persyaratan yang ditetapkan di bawah ini, namun tunduk pada persyaratan dan ketentuan berbeda yang diberlakukan oleh pemberi lisensi Produk Pihak Ketiga tersebut.</p>
                </li>
                <li>
                  <p><b><u>Lisensi Produk.</u></b> Lisensi Dasbor Pengawasan SIPLah adalah Akun Pengawas yang dikembangkan untuk kebutuhan lingkungan Kemendikdasmen, terutama Pusat Data dan Teknologi Informasi, 
                  Biro Umum, Direktorat Jenderal Pendidikan Anak Usia Dini, Pendidikan Dasar, dan Pendidikan Menengah, Dinas Pendidikan Provinsi, Dinas Pendidikan Kabupaten/Kota, Perangkat Pengawas Pusat ataupun Daerah, dan Mitra SIPLah yang bertujuan untuk mengatur akses terhadap Akun Pengawas SIPLah, distribusi, 
                  dan pembagian hak akses sesuai jenis masing-masing Akun Pengawas diasumsikan hanya untuk kepentingan pengawasan transaksi pengadaan barang dan/atau jasa satuan pendidikan, dan audit maupun pemeriksaan. Lisensi ini berlaku untuk program ini dan/atau atau produk/karya lain dimana syarat dan ketentuannya akan disampaikan secara lebih spesifik dalam pemberitahuan yang ditempatkan oleh Kami. Sesuai dengan kepatuhan Anda terhadap persyaratan EULA ini, Kemendikdasmen memberi Anda lisensi pribadi, non eksklusif, tidak dapat dipindahtangankan, dan terbatas pada 1 (satu) akun yang telah Anda ajukan pendaftaranya kepada Kami, dan hanya untuk tujuan yang diuraikan dalam EULA ini. Kecuali dinyatakan secara tegas dalam dokumen ini, EULA ini tidak memberi Anda hak kekayaan intelektual apapun atas Produk. Kemendikdasmen dan para pemasoknya memiliki semua hak yang tidak diberikan kepada Anda secara tegas. Tidak ada hak yang tersirat</p>
                </li>
                <li>
                  <p><b><u>Larangan.</u></b> Anda tidak diizinkan untuk melakukan hal-hal berikut:</p>
                  <ol>
                    <li className="alphabet-list">
                      <p>Menggunakan data apapun yang berada dalam Produk untuk kepentingan pribadi; </p>
                    </li>
                    <li className="alphabet-list">
                      <p>Membuat karya turunan berdasarkan Produk atau bagian atau komponen apapun daripadanya, termasuk, namun tidak terbatas pada, Perangkat Lunak;</p>
                    </li>
                    <li className="alphabet-list">
                      <p>Memproduksi ulang Produk secara keseluruhan atau sebagian;</p>
                    </li>
                    <li className="alphabet-list">
                      <p>Menjual, menyalin, memodifikasi, mendistribusikan, melakukan instalasi, mengalihkan, memberikan lisensi, mengungkapkan, menjalankan dan/atau memindahtangankan atau menyediakan Produk secara keseluruhan maupun sebagian, ke pihak manapun tanpa izin dari Kemendikdasmen;</p>
                    </li>
                    <li className="alphabet-list">
                      <p>Memodifikasi dan/atau mendistribusikan, menerjemahkan, mendekompilasi, menyalin Produk dan/atau berupaya melakukan rekayasa balik keseluruhan atau sebagian Produk dan/atau salinan Produk dan/atau bagian/komponen apapun daripadanya, kecuali dan hanya sejauh aktivitas tersebut diizinkan secara tegas oleh hukum yang berlaku dengan  memperhatikan larangan kontraktual ini;</p>
                    </li>
                    <li className="alphabet-list">
                      <p>Mensublisensikan, memodifikasi, meminjamkan, dan/atau menyewakan Akun Pengawas Produk Anda  kepada pihak lain;</p>
                    </li>
                    <li className="alphabet-list">
                      <p>Mengakses dan/atau menggunakan akses Akun Pengawas tanpa mendapatkan izin dari Kemendikdasmen.</p>
                    </li>
                    <li className="alphabet-list">
                      <p>Menyalin data dari tempat penyimpanan (repository) dan/atau yang tertampil pada  Produk resmi dalam media apapun tanpa mendapatkan persetujuan Kemendikdasmen dan menandatangani Nota Kesepahaman (Memorandum of Understanding) dengan Kemendikdasmen terlebih dahulu.</p>
                    </li>
                    <li className="alphabet-list">
                      <p>Melakukan tindakan apapun yang akan mengakibatkan Produk menjadi tunduk pada perjanjian lisensi apapun, jika belum tunduk pada perjanjian tersebut;</p>
                    </li>
                    <li className="alphabet-list">
                      <p>Menghapus dan/atau mengubah pemberitahuan atau hak milik apapun pada Produk.</p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p><b><u>Penghentian.</u></b> Kemendikdasmen dapat menghentikan pemberian lisensi apabila Anda melanggar persyaratan lisensi dan melakukan hal-hal yang dilarang dan tertera dalam klausul Larangan dalam EULA ini. Anda harus menghentikan semua akses dan/atau penggunaan Produk dan tetap tunduk dengan segala hal yang berkaitan dengan kebutuhan audit, privasi, dan/atau tanggung jawab Pengguna.</p>
                </li>
                <li>
                  <p><b><u>Pembaruan.</u></b> Jika Anda menerima pembaruan atau pemutakhiran, atau versi baru, Produk apapun (“<b>Pembaruan</b>”), Anda harus memiliki lisensi valid untuk versi sebelumnya agar dapat menggunakan Pembaruan tersebut. Semua Pembaruan yang disediakan untuk Anda tunduk pada persyaratan dan ketentuan dalam EULA ini. Jika Anda menerima Pembaruan, berarti Anda dapat terus menggunakan versi Produk sebelumnya dalam kepemilikan, penjagaan, atau kendali Anda. Kemendikdasmen  tidak berkewajiban mendukung versi Produk sebelumnya akibat persetujuan Anda terhadap ketersediaan Pembaruan berdasarkan EULA ini.</p>
                </li>
                <li>
                  <p><b><u>Tanpa Jaminan.</u></b> Kecuali selama Perangkat Lunak disertakan dalam garansi Produk untuk Produk yang menggunakan Perangkat Lunak, Perangkat Lunak dan Perangkat Lunak Pihak Ketiga sebagai Produk mandiri ditawarkan atas dasar “sebagaimana adanya” (As-Is Basis) dan diberikan tanpa jaminan, baik tersurat maupun tersirat. Kemendikdasmen dan para pemasoknya menolak secara tegas semua garansi apapun, baik terkait undang-undang, tersurat maupun tersirat, termasuk, namun tidak terbatas pada, garansi tersirat yang diperdagangkan, kesesuaian atas tujuan tertentu dan non-pelanggaran (Non-Infringement). Kemendikdasmen tidak menyediakan Layanan Pihak Ketiga dan tidak membuat jaminan yang terkait dengan Layanan Pihak Ketiga. Penggunaan Layanan Pihak Ketiga adalah risiko Anda.</p>
                </li>
                <li>
                  <p><b><u>Pengecualian Kerugian Insidental, Konsekuensial, dan Kerugian tertentu lainnya.</u></b> Sejauh diizinkan oleh undang-undang yang berlaku, dalam keadaan apapun Kemendikdasmen atau pemberi lisensi atau pemasoknya tidak bertanggung jawab atas kerugian khusus, insidental, yang menimbulkan hukuman, tidak langsung, atau kerugian konsekuensial apapun penyebabnya (termasuk namun tidak terbatas pada, kerugian akibat hilangnya laba atau informasi rahasia atau informasi lainnya, atas gangguan usaha, cedera pribadi, hilangnya privasi, kegagalan untuk memenuhi kewajiban apapun, termasuk itikad baik atau tindakan yang wajar untuk kelalaian, dan untuk kerugian finansial dan/atau kerugian apapun lainnya) yang timbul karena atau dengan cara apapun terkait dengan penggunaan atau ketidakmampuan untuk menggunakan produk, bagian, atau komponen apapun atau layanan terkait atau Layanan Pihak Ketiga, atau sebaliknya berdasarkan atau sehubungan dengan ketentuan apapun dari EULA ini, bahkan dalam hal kesalahan, wanprestasi (termasuk kelalaian), representasi yang salah, tanggung jawab mutlak (strict liability), pelanggaran kontrak, atau pelanggaran jaminan Kemendikdasmen, pemberi lisensi, atau para pemasoknya.</p>
                </li>
                <li>
                  <p><b><u>Pembatasan Tanggung Jawab.</u></b> Kemendikdasmen tidak bertanggung jawab, dalam keadaan apapun, untuk tanggung jawab, kerugian, ganti rugi, biaya, pengeluaran langsung dan/atau tidak langsung apapun, denda dan penalti termasuk hilangnya laba, penghematan usaha atau yang diantisipasi, hilangnya data, kerugian konsekuensial lain ataupun untuk suatu kerugian immateriil apapun, yang diderita atau ditanggung oleh Anda akibat persetujuan Anda terhadap EULA ini.</p>
                </li>
                <li>
                  <p><b><u>Informasi Pribadi.</u></b> Pengumpulan, penggunaan, dan pengungkapan yang dilakukan Kemendikdasmen atas informasi yang dapat mengidentifikasi diri Anda sehubungan dengan penggunaan Anda atas Produk akan dikelola oleh Kemendikdasmen dan Kemendikdasmen akan melakukan segala tindakan yang diperlukan untuk mencegah kemungkinan terjadinya kelalaian dan/atau penyalahgunaan data dan informasi Anda. Kemendikdasmen tidak akan membagikan data dan informasi Anda kepada pihak ketiga manapun tanpa persetujuan Anda.</p>
                </li>
                <li>
                  <p><b><u>Penggantian Kerugian.</u></b> Dengan menerima EULA, berarti Anda setuju untuk mengganti kerugian dan membebaskan Kemendikdasmen beserta staf, karyawan, agen, anak perusahaan, afiliasi, dan mitra lainnya dari Kemendikdasmen (termasuk, tanpa batasan, dari semua ganti rugi, tanggung jawab, pelunasan, ongkos dan biaya pengacara) untuk setiap kerugian langsung, tidak langsung, insidental, khusus, maupun konsekuensial, yang timbul dari, terkait dengan, atau akibat dari penggunaan Anda atas Produk atau hal lain yang terkait dengan Produk, termasuk, namun tidak terbatas pada, penggunaan setiap Layanan Pihak Ketiga akibat persetujuan Anda terhadap EULA ini atau karena suatu gugatan atau tuntutan yang diajukan oleh pihak manapun termasuk namun tidak terbatas karena adanya pelanggaran hak kekayaan intelektual pihak ketiga, kebocoran data, atau hak-hak lain atau suatu gugatan atau tuntutan yang diajukan oleh pihak manapun, yang diakibatkan oleh kelalaian dan/ atau kesalahan Anda dalam mematuhi ketentuan EULA ini dan/ atau peraturan perundang-undangan yang berlaku.</p>
                </li>
                <li>
                  <p><b><u>Domisili Hukum.</u></b> EULA ini tunduk, diatur oleh, dan ditafsirkan berdasarkan hukum Republik Indonesia.</p>
                </li>
                <li>
                  <p><b><u>Penyelesaian Perselisihan.</u></b> Anda dan Kami sepakat untuk menyelesaikan setiap perselisihan maupun sengketa apapun yang timbul sehubungan dengan penggunaan Produk secara musyawarah dan mufakat. Apabila dalam jangka waktu 30 (tiga puluh) hari dilaksanakannya musyawarah tidak mencapai mufakat maka akan diselesaikan melalui forum penyelesaian sengketa di Pengadilan Jakarta Selatan.</p>
                </li>
                <li>
                  <p><b><u>Pengalihan.</u></b> Anda tidak dapat memindahtangankan atau mengalihkan hak apapun dalam Produk akibat persetujuan Anda terhadap EULA ini, kecuali Anda mendapatkan izin dari Kemendikdasmen. </p>
                </li>
                <li>
                  <p><b><u>Lain-lain.</u></b> Apabila ada ketentuan pada EULA ini yang menjadi tidak sah, tidak berlaku atau tidak dapat dilaksanakan berdasarkan ketentuan perundang-undangan, maka keabsahan, keberlakuan atau pelaksanaan dari ketentuan lainnya dalam EULA ini tidak akan menjadi terpengaruh atau terganggu dan akan terus berlaku selama diperbolehkan berdasarkan ketentuan yang berlaku.</p>
                </li>
                <li>
                  <p><b><u>Penerimaan.</u></b> Dengan mengakses, mengunduh, mengkonfigurasi, mengatur, mendaftar akun pengawas dan/atau menggunakan semua atau bagian apapun dari Dasbor Pengawasan SIPLah maka Anda mengerti dan setuju untuk tunduk mematuhi dan terikat dengan EULA ini.  </p>
                </li>
              </ol>
            </div>
            <div className="pb-4 float-right">
              {/* <Checkbox onChange={(e) => onChangeCheck(e.target.checked)}><span style={{color: "#1890ff"}}>Saya Setuju</span></Checkbox> */}
              <Button
                type="primary"
                size="large"
                shape="round"
                loading={isSubmitAgreementLoading}
                onClick={() => onSubmitAgreement(true)}
              >
                Saya Setuju
              </Button>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    )
  }
}

export default connect(
  ({ auth }) => ({ auth }),
  { agreementUpdate }
)(AgreementModal)