import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { last, first, map as lodashMap } from 'lodash'
import { Layout, Menu, Tooltip } from 'antd'
import {
  HomeOutlined,
  TeamOutlined,
  // TransactionOutlined,
  // BankOutlined,
  ShopOutlined,
  // DropboxOutlined,
  LoginOutlined,
  // DatabaseOutlined,
  DollarOutlined,
  FieldTimeOutlined,
  DeploymentUnitOutlined,
  BookOutlined,
} from '@ant-design/icons';
import styled from 'styled-components'
import history from '../../utils/History'
import { updateSiteConfiguration } from '../../actions/site'
import routes from '../../routes'

const { Sider } = Layout
const { SubMenu } = Menu

const SideNav = styled(Sider)`
  .ant-menu-root.ant-menu-inline{
    margin-top: 4px;
  }
  ::-webkit-scrollbar {
    width: 4px;
  };
  ::-webkit-scrollbar-track {
    background: lightgrey;
  };
  ::-webkit-scrollbar-thumb {
    background: darkgray;
  };
  ::-webkit-scrollbar-thumb:hover {
    background: darkgrey; 
  };
`
/*const WrapperAvatar = styled(Row)`
  margin: 10px 0 0 10px;
  .avatar {
    text-align: ${(props) => (props.iscollapsed ? 'center' : 'left')};
  };
  .name {
    display: ${(props) => (props.iscollapsed ? 'none' : 'block')};
    h4 {
      color: white;
      margin-bottom: 0;
    }
  };
`*/

class Sidebar extends React.Component {
  state = {
    role: null,
    newRoutes: []
  }
  componentDidMount(){
    if (this.props?.auth?.roles) {
      this.setState({
        role: first(this.props.auth.roles)
      })
    }

    let reRoutes = []
    routes.forEach(item => {
      const newValue = item?.allowedRoles?.includes(first(this.props.auth.roles)?.role_name) && item
      newValue && reRoutes.push(newValue)
    })

    this.setState({
      newRoutes: lodashMap(reRoutes, 'path')
    })
  }

  handleKeys(param, isOpenSub) {
    const { updateSiteConfiguration } = this.props

    if (isOpenSub) {
      updateSiteConfiguration('activeSubPage', last(param))
    } else {
      updateSiteConfiguration('activePage', first(param.keyPath))
      history.push(`/${first(param.keyPath)}`)
      if (param.keyPath.length > 1) {
        updateSiteConfiguration('activeSubPage', last(param.keyPath))
      } else {
        updateSiteConfiguration('activeSubPage', first(param.keyPath))
      }
    }
  }

  handleCheckAccess = (path) => {
    return this.state.newRoutes.some((item) => path.includes(item))
  }

  render() {
    const { newRoutes } = this.state
    const { site } = this.props
    return (
      <SideNav
        theme="light"
        breakpoint="lg"
        collapsedWidth="0"
        width={250}
        onBreakpoint={broken => {
          // console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          // console.log(collapsed, type);
        }}
      >
        <div className="logo">
          <img src="/Assets/img/kemdikbud_logo.png" className="img-fluid px-3" alt=""/>
        </div>
        <Menu
          theme="light"
          mode="inline"
          selectedKeys={[site.activePage]}
          openKeys={[site.activeSubPage]}
          onClick={(e) => this.handleKeys(e)}
          onOpenChange={(e) => this.handleKeys(e, true)}
          items={[
            newRoutes?.includes('/dashboard') && {
              key: "dashboard",
              icon: <HomeOutlined />,
              label: "Ringkasan"
            },
            newRoutes?.includes('/sla-compliance') && {
              key: "sla-compliance",
              icon: <FieldTimeOutlined />,
              label: "Tingkat Kepatuhan SLA"
            },
            newRoutes?.includes('/mapping-breaches') && {
              key: "mapping-breaches",
              icon: <BookOutlined />,
              label: (
                <Tooltip placement="top" title="Ringkasan Ketidaksesuaian Pemetaan Belanja">
                  Ringkasan Ketidaksesuaian Pemetaan Belanja
                </Tooltip>
              )
            },
            this.handleCheckAccess(['/transaction-all', '/transaction-list', '/source-transaction']) && {
              key: "transaction",
              icon: <DollarOutlined />,
              label: "Transaksi",
              children: [
                newRoutes?.includes('/transaction-all') && {
                  key: "transaction-all",
                  label: "Daftar Transaksi"
                },
                newRoutes?.includes('/source-transaction') && {
                  key: "source-transaction",
                  label: "Transaksi Sumber Dana"
                }
              ]
            },
            this.handleCheckAccess(['/pdn-umkm-list', '/goods-list', '/store-list']) && {
              key: "stores-goods",
              icon: <ShopOutlined />,
              label: "Barang & Penyedia",
              children: [
                newRoutes?.includes('/pdn-umkm-list') && {
                  key: "pdn-umkm-list",
                  label: "PDN dan UMKM"
                },
                newRoutes?.includes('/goods-list') && {
                  key: "goods-list",
                  label: "Daftar Barang"
                },
                newRoutes?.includes('/store-list') && {
                  key: "store-list",
                  label: "Daftar Penyedia"
                },
                newRoutes?.includes('/goods-transaction') && {
                  key: "goods-transaction",
                  label: "Daftar Transaksi Barang"
                },
                newRoutes?.includes('/merchants-transaction') && {
                  key: "merchants-transaction",
                  label: "Daftar Transaksi Mitra"
                }
              ]
            },
            this.handleCheckAccess(['/data-gap']) && {
              key: "data-agregate",
              icon: <DeploymentUnitOutlined />,
              label: "Data Agregasi",
              children: [
                { key: "data-gap", label: "Selisih Data" },
                { key: "general-data-quality", label: "Kualitas Data Umum" },
                { key: "detail-data-quality", label: "Kualitas Data Detail" },
                {
                  key: "dqm-integration-transaction",
                  label: (
                    <Tooltip placement="top" title="Data Yang Perlu Diperbaiki Sesuai dengan Standar Kualitas Data">
                      Data Yang Perlu Diperbaiki Sesuai dengan Standar Kualitas Data
                    </Tooltip>
                  )
                }
              ]
            },
            newRoutes?.includes('/sso-client') && {
              key: "sso-client",
              icon: <LoginOutlined />,
              label: "Klien SSO"
            },
            this.handleCheckAccess(['/user-list', '/approval-permission']) && {
              key: "user-management",
              icon: <TeamOutlined />,
              label: "Manajemen Pengguna",
              children: [
                newRoutes?.includes('/user-list') && {
                  key: "user-list",
                  label: "Daftar Pengguna"
                },
                newRoutes?.includes('/approval-permission') && {
                  key: "approval-permission",
                  label: "Persetujuan Pengguna"
                },
                newRoutes?.includes('/approval-permission/create') && {
                  key: "approval-permission/create",
                  label: "Tambah Pengguna"
                },
                newRoutes?.includes('/approval-permission/log') && {
                  key: "approval-permission/log",
                  label: "Aktivitas Pengguna"
                }
              ]
            }
          ].filter(Boolean)} // Menghapus nilai `false` dari array
        />

      </SideNav>
    )
  }
}


export default withRouter(connect(
  ({ site, auth }) => ({ site, auth }),
  { updateSiteConfiguration },
)(Sidebar))
